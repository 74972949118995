@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Chrusty Rock";
  src: url("../public/font/ChrustyRock.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Chrusty Rock";
  src: url("../public/font/Newretrostyle3d-ygjm.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.font-chrusty {
  font-family: "Chrusty Rock", sans-serif;
}

.font-retro {
  font-family: "New Retro Style 3D", sans-serif;
}

.max-width {
  @apply lg:mx-auto lg:px-24 xl:px-48 px-5;
}
.bg-gradient {
  @apply bg-gradient-to-r from-sky-500 via-purple-500 to-pink-600;
}
.text-gradient {
  @apply bg-clip-text bg-gradient text-transparent;
}
.section {
  @apply pt-28 xl:pt-28 2xl:pt-44;
}

.text-content {
  @apply dark:text-light-content text-dark-content;
}


* {
  scroll-behavior: auto !important; /* Ensure native smooth scrolling is off */
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  scroll-behavior: smooth; /* Smooth scrolling */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

nav a.active {
  background: -webkit-linear-gradient(rgb(41, 89, 202), rgb(66, 234, 97));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 5px;
}

.greetings-container {
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.2); /* Glowing shadow */
  filter: blur(0px); /* Subtle blur */
  transition: filter 0.6s ease, box-shadow 0.6s ease; /* Smooth effect */
}

.transition-wrapper {
  position: relative;
  z-index: 10;
  min-height: 100vh;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-bg {
  background: linear-gradient(-45deg, #0d0d0d, #1a1a1a, #2a2a2a, #3a3a3a);
  background-size: 400% 400%;
  animation: gradientMove 10s ease infinite;
}

.stars {
  width: 1px;
  height: 1px;
  position: absolute;
  background: white;
  box-shadow: 2vw 5vh 2px rgb(255, 255, 255), 10vw 8vh 2px white, 15vw 15vh 1px white, 22vw 22vh 1px white, 28vw 12vh 2px white, 32vw 32vh 1px white, 38vw 18vh 2px white, 42vw 35vh 1px white, 48vw 25vh 2px white, 53vw 42vh 1px white,
    58vw 15vh 2px white, 63vw 38vh 1px white, 68vw 28vh 2px white, 73vw 45vh 1px white, 78vw 32vh 2px white, 83vw 48vh 1px white, 88vw 20vh 2px white, 93vw 52vh 1px white, 98vw 35vh 2px white, 5vw 60vh 1px white, 12vw 65vh 2px white,
    18vw 72vh 1px white, 25vw 78vh 2px white, 30vw 85vh 1px white, 35vw 68vh 2px white, 40vw 82vh 1px white, 45vw 92vh 2px white, 50vw 75vh 1px white, 55vw 88vh 2px white, 60vw 95vh 1px white, 65vw 72vh 2px white, 70vw 85vh 1px white,
    75vw 78vh 2px white, 80vw 92vh 1px white, 85vw 82vh 2px white, 90vw 88vh 1px white, 95vw 75vh 2px white;
  animation: twinkle 8s infinite linear;
}

.shooting-star {
  position: absolute;
  width: 100px;
  height: 2px;
  background: linear-gradient(90deg, #8a2be2, transparent);
  animation: shoot 3s infinite ease-in;
}

.shooting-star:nth-child(1) {
  top: 20%;
  left: -100px;
  animation-delay: 0s;
}

.shooting-star:nth-child(2) {
  top: 35%;
  left: -100px;
  animation-delay: 1s;
}

.shooting-star:nth-child(3) {
  top: 50%;
  left: -100px;
  animation-delay: 2s;
}

.shooting-star::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(138, 43, 226, 0.8), rgba(106, 90, 205, 0.5), rgba(0, 166, 255, 0.3));
  filter: blur(4px);
}

/* Grainy Effect */
.grainy-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none; /* Ensures it doesn't interfere with clicks */
  background: url("https://grainy-gradients.vercel.app/noise.svg");
  opacity: 0.2; /* Adjust grain intensity */
  mix-blend-mode: soft-light; /* Blends grain effect naturally */
  animation: grainAnimation 0.2s steps(4) infinite;
}

@keyframes grainAnimation {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 10% -10%;
  }
  50% {
    background-position: -10% 10%;
  }
  75% {
    background-position: 5% -5%;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes shoot {
  0% {
    transform: translateX(0) translateY(0) rotate(25deg);
    opacity: 1;
  }
  100% {
    transform: translateX(120vw) translateY(50vh) rotate(25deg);
    opacity: 0;
  }
}

/* Additional twinkling stars with different animation timing */
.stars::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 1px;
  background: white;
  box-shadow: 8vw 12vh 2px white, 16vw 18vh 1px white, 24vw 25vh 2px white, 33vw 15vh 1px white, 41vw 28vh 2px white, 49vw 35vh 1px white, 57vw 22vh 2px white, 65vw 42vh 1px white, 73vw 28vh 2px white, 81vw 48vh 1px white,
    89vw 32vh 2px white, 97vw 45vh 1px white, 3vw 68vh 2px white, 11vw 75vh 1px white, 19vw 82vh 2px white, 27vw 88vh 1px white, 35vw 72vh 2px white, 43vw 85vh 1px white, 51vw 92vh 2px white, 59vw 78vh 1px white;
  animation: twinkle 6s infinite linear reverse;
}

@keyframes kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
.kenburns {
  animation: kenburns 10s alternate infinite ease-in-out;
}

canvas {
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
}

.travel-card {
  flex-shrink: 0; /* Prevent shrinking */
  height: 60vh; /* Optional height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  will-change: transform; /* Optimize animations */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}
.no-scrollbar {
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}
@keyframes glow {
  0% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  50% {
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
}

.glow-text {
  animation: glow 1.5s infinite alternate ease-in-out;
}
.folded-corner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background: linear-gradient(135deg, #555 50%, transparent 50%);
}
